/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Box, Flex, Button, Link } from 'rebass';

import { H2, H3, H4, P } from '../../components/Text';
import { Cross } from '../../components/Cross';
import { ProgramLayout } from '../../layouts/ProgramLayout';
import { Ul, Li } from '../../components/List';
import { Container } from '../../components/Container';

const CFPHeaderGrid: React.FC = ({ children }) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      rowGap: [70, 80, 100],
      mb: 72,
    }}
  >
    {children}
  </div>
);

interface CFPSectionProps {
  title: string;
  children?: React.ReactNode;
}
const CFPSection: React.FC<CFPSectionProps> = ({
  title,
  children,
  ...restProps
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <div {...restProps}>
      <Flex
        alignItems="baseline"
        justifyContent="space-between"
        role="button"
        sx={{
          cursor: 'pointer',
          py: [50, 60, 72],
          borderTop: 'solid 1px',
          borderColor: 'text',
        }}
        onClick={() => setActive(s => !s)}
      >
        <H2 sx={{ width: '90%' }}>{title}</H2>
        <Cross
          sx={{ transform: isActive ? 'rotateZ(45deg)' : 'rotateZ(0deg)' }}
        />
      </Flex>
      {isActive && (
        <Box
          sx={{
            lineHeight: '22px',
            mt: 3,
            fontSize: [0, 1],
            whiteSpace: 'pre-line',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

interface CFPThemeProps {
  title: string;
  body: string;
}
const CFPTheme: React.FC<CFPThemeProps> = ({ title, body }) => (
  <Flex sx={{ flexDirection: ['column', 'column', 'row'], mb: [50, 60, 72] }}>
    <H3
      sx={{
        mr: 10,
        width: ['100%', '100%', '50%'],
        overflowWrap: 'break-word',
        textAlign: 'center',
        fontSize: ['18px', '18px', 'auto'],
      }}
    >
      {title}
    </H3>
    <P sx={{ ml: 10, width: ['100%', '100%', '50%'] }}>{body}</P>
  </Flex>
);

const CFPPage: React.FC = () => {
  return (
    <ProgramLayout programDays={[]}>
      {({ tzOffset }) => (
        <Container>
          <CFPHeaderGrid>
            <Box sx={{ gridColumn: ['1/3', '1/3', '1 / 2'] }}>
              <H2>Merci!</H2>
              <H4>
                L'appel aux propositions pour la conférence principale est
                terminé.
              </H4>
              <P>
                Revenez à la mi-novembre pour voir l'annonce de la programmation
                complète.
                <br />
                Les soumissionaires peuvent s'attendre à un retour aux alentours
                du <strong sx={{ fontWeight: 'bold' }}>30 octobre 2020</strong>.
              </P>
              <P>
                Si vous désirez soumettre pour les Interaction Awards 2020,
                l'appel aux propositions est ouvert jusqu'au <br />{' '}
                <strong sx={{ fontWeight: 'bold' }}>31 octobre 2020</strong>.
                <br />
                <Link href="https://awards.ixda.org/" target="_blank">
                  En savoir plus ↗
                </Link>
              </P>
            </Box>
            <Box></Box>
            <Box sx={{ gridColumn: ['1/3', '1/3', '2 / 3'] }}>
              <P>
                Nous sommes fiers de partager que l'ensemble des sélections que
                nous avons reçues en était un des plus divers, reflétant la
                portée globale de la communauté IxDA.
              </P>
              <P>Total: 374 soumissions (65 ateliers, 307 conférences)</P>
              <Ul>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>52%</strong> des
                  soumissionnaires s'identifient en tant que femmes et{' '}
                  <strong sx={{ fontWeight: 'bold' }}>7%</strong> de genre non
                  conforme, non binaire, ou bispirituel.
                </Li>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>59 pays</strong>, et tous
                  les continents, sauf l'Antarctique
                </Li>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>74%</strong> des
                  soummissionnaires n'ont jamais donné de conférence à un
                  événement IxDA auparavant
                </Li>
                <Li>
                  <strong sx={{ fontWeight: 'bold' }}>16</strong>{' '}
                  soumissionnaires courageux seraient des conférenciers pour la
                  première fois
                </Li>
                <Li>
                  Notre thème le plus populaire avec{' '}
                  <strong sx={{ fontWeight: 'bold' }}>37%</strong> des
                  soumissions est "New World to Design"
                </Li>
              </Ul>
            </Box>
            <Box
              sx={{
                borderColor: 'text',
                borderBottom: 'solid 1px',
                gridColumn: '1/3',
              }}
            />
          </CFPHeaderGrid>
          <CFPHeaderGrid>
            <P sx={{ gridColumn: ['1/3', '1/3', '2 / 3'] }}>
              Politique. Pandémies. Perturbations économiques. Injustices
              sociales. Crises climatiques. Vol massif de données. Hypertrucage.
              Notre thème, "Le design en temps périlleux", postule la question :
              "Comment détruirons-nous les paradigmes nocifs s'infiltrant dans
              toutes les facettes de notre vie ?"
            </P>
            <H2 sx={{ gridColumn: '1 / 3', textAlign: 'center' }}>
              Nous cherchons des conférences, des panels, des tables de
              discussions et des ateliers par des humains de tous horizons. Nous
              encourageons un large éventail de perspectives sur le design
              d'interaction - architectes, gestionnaires, artistes, stratèges,
              experts en communication, sans oublier les designers d’interfaces
              et d'expérience utilisateur.
            </H2>
            <P
              sx={{
                gridColumn: '1 / 3',
                width: '80%',
                textAlign: 'center',
                justifySelf: 'center',
              }}
            >
              L'équipe d'Interaction 21 est à la disposition de tous les
              présentateurs pour les épauler ou réviser leurs présentations.
              Vous pouvez faire votre présentation dans la langue de votre
              choix, et nous vous fournirons une traduction si nécessaire. Les
              conférences seront sous-titrées en direct.
            </P>
            <div
              sx={{
                gridColumn: ['1/3', '1/3', '2 / 3'],
                width: ['auto', 'auto', '66%'],
              }}
            >
              <H3>Anonymat</H3>
              <P>
                Le format en ligne d'IxD21 a l'avantage de permettre aux
                conférenciers de présenter dans un total anonymat. Nous
                encourageons les sujets délicats, controversés ou qui simplement
                pourraient être mieux assimilés lorsque présentées dans
                l’anonymat.
              </P>
            </div>
          </CFPHeaderGrid>

          <CFPSection title="Thèmes">
            <Box sx={{ mb: [60, 60, 100] }}>
              <P sx={{ width: ['100%', '100%', '50%'] }}>
                Les thèmes clés de cette année sont sur votre terrain de jeu,
                mais sentez-vous libre de sortir du bac à sable. Notre format
                non traditionnel permet une certaine latitude quant aux thèmes
                explorés et retenus dont vous pourrez profiter lors de votre
                soumission.
              </P>
            </Box>
            <CFPTheme
              title="#Design_Economics"
              body="
          L’économie de marché nous a transformé. Les intérêts commerciaux 
          et les  rapaces milliardaires envahissent nos données et infiltrent 
          nos espaces numériques. La vie privée, la confiance et la véracité 
          sont en péril. Nous sommes de plus en plus divisés, désorientés et 
          exploitables. Comment lutter contre l’emprise tentaculaires de la 
          course aux profits en design ? 
          "
            />
            <CFPTheme
              title="#New_World_to_Design"
              body="
          Mises en lumière par Covid-19, teinté de changement climatique 
          brutal, les inégalités sociétales s’imposent: nous sommes en 
          état d'urgence. Notre monde est en feu et nous incite à 
          converger vers l'échelle locale et mondiale. Stoïquement, nous 
          nous devons de saisir l'opportunité offerte par ces crises.
          "
            />
            <CFPTheme
              title="#Dark_Design"
              body="
          Le design est profondément humain. La sexualité, la dépendance, 
          les tares et les désirs sont pris en compte, voire exploités. 
          Nous concevons des produits addictifs et nuisibles, et tout ça 
          pour des primes sur le compte en banque. Assez de laisser-faire, 
          relevons nos manches et salissons-nous franchement."
            />
            <CFPTheme
              title="#Designing_Design"
              body="Les bons designers aident les autres à s'épanouir en 
          les guidant au travers de multiples périls.  Le design peut 
          être une communauté forte est unie et organisée - il est temps 
          de parler d’union. Nous appelons les leaders-designers, ceux qui 
          sont dans les tranchées. Aux armes, Designers! Rassemblons-nous 
          pour partager nos réussites, et surtout nos échecs, avec toutes 
          les leçons dont nous en avons tirées."
            />
            <CFPTheme
              title="#Future"
              body="La science-fiction et la fiction spéculative nous terrifient, 
          et pourtant elles nous incitent à imaginer des possibilités 
          révolutionnaires avec courage. Pour survivre au péril, nous 
          devons brandir le design comme un défi créatif.  Il nous faut 
          explorer en profondeur nos hypothèses et déficeler nos préjugés 
          sur notre futur.. La déconstruction sera notre échafaudage."
            />
          </CFPSection>
          <CFPSection title="Formats">
            <Box sx={{ mb: 60 }}>
              <P>
                Nous recherchons des conférences de différentes longueurs pour
                tenir compte de la diversité des approches et des points de vue
                :
              </P>
            </Box>
            <H3>Conférence</H3>
            <Box
              sx={{
                my: [60, 60, 100],
                ml: ['-5%', 0, '10%'],
                width: ['110%', '100%', '80%'],
                display: 'grid',
                gridAutoFlow: 'column',
                gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                gridTemplateRows: ['repeat(4, auto)', 'auto auto'],
                rowGap: [48, 30, 12],
                '*': {
                  textAlign: 'center',
                },
              }}
            >
              <H3>00:15:00</H3>
              <P>
                15 minutes pour décrire un cas d'utilisation ou un concept
                simple ;
              </P>
              <H3>00:25:00</H3>
              <P>
                25 minutes pour présenter un concept ou un parcours historique
                très riche, complet et bien articulé.
              </P>
            </Box>
            <H3>Atelier</H3>
            <Box sx={{ my: [60, 60, 100] }}>
              <P>
                Si vous souhaitez animer des groupes plus restreints et réaliser
                des exercices interactifs, vous êtes les bienvenus de proposer
                un atelier.
              </P>
              <Flex
                sx={{
                  flexDirection: ['column', 'column', 'row'],
                }}
              >
                <P sx={{ width: ['100%', '100%', '50%'] }}>
                  Nous aurons un mélange d'ateliers{' '}
                  <strong sx={{ fontWeight: 700 }}>courts (2h)</strong> et{' '}
                  <strong sx={{ fontWeight: 700 }}>longs (4h)</strong>.
                </P>
                <P sx={{ width: ['100%', '100%', '50%'] }}>
                  Des activités très pratiques avec des résultats tangibles aux
                  activités plus visionnaires, demandez-vous toujours ce que les
                  participants retireraient de votre session, et bien sûr, si
                  cela est pertinent par rapport au thème général de la
                  conférence.
                </P>
              </Flex>
            </Box>
            <H3>Table ronde</H3>
            <Box sx={{ my: 60 }}>
              <P>
                Cette année, nous ouvrons la scène aux tables rondes ou aux
                anti-conférences, dans le but d'engager le public avec un ou
                plusieurs experts - c'est-à-dire VOUS! Vous introduirez un sujet
                pendant environ 10 minutes, puis animerez une discussion de 20
                ou 50 minutes. Inscrivez-vous avec vos amis !
              </P>
            </Box>
            <H3>Mentorat 1 sur 1</H3>
            <Box sx={{ my: 60 }}>
              <P>
                Nous ouvrons la participation à des experts qui ont du temps à
                donner pour mentorer des designers avec moins d’expérience. Pour
                participer, cochez la section appropriée dans le formulaire de
                soumission.
              </P>
            </Box>
          </CFPSection>

          <CFPSection title="Comment soumettre une proposition">
            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>Après la soumission de votre proposition</H4>
              </Box>
              <Box sx={{ flex: 2 }}>
                <P>
                  Vos propositions sont examinées par notre comité de sélection.
                  La qualité, l'expérience des intervenants, la fidélité au
                  thème et l'originalité vont guider nos choix.
                </P>
                <P>Dates clés:</P>
                <Ul>
                  <Li>
                    Date limite de soumission des propositions pour les
                    conférences et les ateliers{' '}
                    <strong sx={{ fontWeight: 700 }}>25 septembre 2020</strong>
                  </Li>
                  <Li>
                    Confirmation de la sélection:{' '}
                    <strong sx={{ fontWeight: 700 }}>30 octobre 2020</strong>
                  </Li>
                  <Li>
                    Annonce du programme complet et des ateliers:{' '}
                    <strong sx={{ fontWeight: 700 }}>16 novembre 2020</strong>
                  </Li>
                </Ul>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>Qu'est-ce que vous y gagnez ?</H4>
              </Box>
              <Box sx={{ flex: 2 }}>
                <P>
                  En tant qu'orateur ou animateur, vous serez en mesure de
                  façonner le discours de la communauté de l'Interaction Design,
                  ainsi que de vous vanter d'être parmi les premiers leaders
                  d'opinion sélectionnés pour figurer sur la nouvelle plateforme
                  IxDTV.
                </P>
                <P>
                  Pour chaque conférence, vous recevrez un billet gratuit pour
                  Interaction Week 21 et des honoraires. Pour chaque atelier,
                  vous recevez 50 % des recettes de la vente de vos billets
                  d'atelier et 1 billet gratuit pour la conférence.
                </P>
                <P>
                  Tous les participants selectionnés recevront également de
                  l'assistance technique, des instructions, du soutien et du
                  matériel d'enregistrement nécessaires pour garantir la
                  meilleure valeur de production possible pour leur segment.
                </P>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>Détails sur l'anonymat</H4>
              </Box>
              <Box sx={{ flex: 2 }}>
                <P>
                  S'inspirant des lignes directrices en vigueur dans le
                  journalisme et le droit canadiens, IxD21 accordera l'anonymat
                  pour les raisons suivantes :
                </P>
                <Ul>
                  <Li>
                    L'information obtenue ne peut l’être que sous le couvert de
                    la confidentialité.
                  </Li>
                  <Li>
                    La volonté de la source de donner une conférence dépend de
                    l'anonymat.
                  </Li>
                  <Li>L'information doit être d'intérêt public.</Li>
                  <Li>
                    La divulgation de l'identité peut causer un préjudice à la
                    partie qui présente l'exposé.
                  </Li>
                </Ul>
                <P>
                  Pour soumettre anonymement, écrivez-nous au{' '}
                  <a
                    sx={{ variant: 'variants.link' }}
                    href="mailto:interaction21@protonmail.com"
                  >
                    interaction21@protonmail.com
                  </a>{' '}
                  avec les détails de votre conférence. Merci de prendre en
                  considération les points mentionnés ci-haut. Nous ferons un
                  retour au plus tard le 30 octobre.
                </P>
                <H4>Vérification</H4>
                <P>
                  La confiance de notre communauté de design est le vent en
                  poupe d'IxDA. IxD21 mènera un processus d'examen approfondi et
                  vérifiera les faits des entretiens anonymes afin de garantir
                  la crédibilité de nos sources.
                </P>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
              <Box sx={{ flex: 1, mr: 20 }}>
                <H4>Prêt.e?</H4>
              </Box>
              <Box sx={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                  as="a"
                  href="https://ixda.submittable.com/submit/168579/interaction-21"
                  target="_blank"
                  sx={{
                    alignItems: 'center',
                    lineHeight: '32px',
                    textAlign: 'left',
                    cursor: 'pointer',
                  }}
                >
                  Soumettre une proposition
                </Button>
              </Box>
            </Flex>
          </CFPSection>
        </Container>
      )}
    </ProgramLayout>
  );
};

export default CFPPage;
